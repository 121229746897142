import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReactResizeDetector from 'react-resize-detector';

import HeaderImage from '../blocks/HeaderImage';
import CallToAction from '../blocks/CallToAction';
import ProductTeaserBlock from '../blocks/ProductTeaserBlock';
// import { clean } from '../Utils/cleanCockpitData';

import ProductDescription from './ProductDescription';
import DefaultLayout from '../layout/Default';
import { graphql } from 'gatsby';

ProduktPageTemplate.propTypes = {
  pageContext: PropTypes.shape().isRequired,
};

function ProduktPageTemplate({ pageContext, data }) {
  const { node, contactData } = pageContext;
  const { label, quote, productDescription, contacts, callToAction, header, relatedProducts } = data.item;
  const { t } = useTranslation('products');

  return (
    <DefaultLayout>
      <HeaderImage data={header} />
      <div
        className="defaultWidth"
        style={{
          paddingBottom: '4em',
        }}
      >
        <ReactResizeDetector handleWidth>
          {(size) => {
            const width = size ? size.width : 1000;
            return (
              <ProductDescription
                label={label}
                data={productDescription}
                contacts={contacts}
                quote={quote}
                twoColumns={width > 900}
              />
            );
          }}
        </ReactResizeDetector>
      </div>
      <CallToAction data={callToAction} contactData={contactData} />
      <ProductTeaserBlock data={relatedProducts} title={t('more')} />
    </DefaultLayout>
  );
}

export default ProduktPageTemplate;

export const ProductTemplateQuery = graphql`
  query ProductTemplateQuery($id: String, $language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    item: cockpitProducts(id: { eq: $id }) {
      id
      lang
      label
      image {
        id
        publicURL
      }
      header {
        id
        line1
        line2
        textPosition
        published
        linkTarget
        linkLabel
        madeInAustriaOverlay
        height
        desiredAspectRatio
        image {
          processed: childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 2000, placeholder: NONE)
          }
        }
        mobileImage {
          id
          publicURL
          processed: childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 900, placeholder: NONE)
          }
        }
      }
      callToAction {
        id
        published
        title
        text
        buttonLabel
        image {
          id
          processed: childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
      }
      contacts {
        id
        name
        jobTitle
        alternateJobTitle
        telephone
        email
        image {
          id
          processed: childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 150, placeholder: NONE)
          }
        }
      }
      quote {
        quote
        originator
      }
      productDescription {
        title
        text
        title_advantages
        advantages
        downloads {
          id
          label
          published
          file {
            id
            publicURL
            extension
            size
          }
        }
      }
      relatedProducts {
        id
        label
        image {
          id
          processed: childImageSharp {
            fluid(maxWidth: 400) {
              base64
              tracedSVG
              aspectRatio
              src
              srcSet
              sizes
              originalImg
              originalName
              presentationWidth
              presentationHeight
            }
          }
        }
      }
    }
  }
`;
